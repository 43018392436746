.containerInner {
  border-bottom: 1px solid var(--color-primary);
  border-top: 1px solid var(--color-primary);
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
}

.header {
  margin-top: 32px;
}

.navItem {
  padding-bottom: 13px;
  padding-top: 18px;
  text-align: center;
  text-transform: uppercase;
}

.navItemWithBorder {
  border-right: 1px solid var(--color-primary);
}